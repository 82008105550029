import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { Card } from 'semantic-ui-react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const imagesQuery = graphql`
  query {
    starwars: file(relativePath: { eq: "starwars.png"}) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    strangerthings: file(relativePath: { eq: "strangerthings.png"}) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    westworld: file(relativePath: { eq: "westworld.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gameOfThrones: file(relativePath: { eq: "game-of-thrones.png"}) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    breakingBad: file(relativePath: { eq: "breaking-bad.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    theLastOfUs: file(relativePath: { eq: "the-last-of-us.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    houseOfTheDragon: file(relativePath: { eq: "house-of-the-dragon.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const IndexPage = () => (
  <Layout>
    <SEO />
    <h1>Help Center</h1>
    <p>
      Select which project you need a support below.
      <br />
      You can see our FAQ and Terms of Service for each service.
      <br />
      You can also see our Privacy Policy for all services.
    </p>
    <br />
    <StaticQuery
      query={imagesQuery}
      render={data => (
        <Card.Group itemsPerRow="2" stackable>
          <Card
            as={Link}
            to="/starwars/"
            header="Star Wars Intro Creator"
            image={<Img fluid={data.starwars.childImageSharp.fluid} />}
            description="See our FAQ and the Terms of Service"
          />
          <Card
            as={Link}
            to="/strangerthings/"
            header="Stranger Things Intro Creator"
            image={<Img fluid={data.strangerthings.childImageSharp.fluid} />}
            description="See our FAQ and the Terms of Service"
          />
          <Card
            as={Link}
            to="/westworld/"
            header="Westworld Intro Creator"
            image={<Img fluid={data.westworld.childImageSharp.fluid} />}
            description="See our FAQ and the Terms of Service"
          />
          <Card
            as={Link}
            to="/game-of-thrones/"
            header="Game of Thrones Intro Creator"
            image={<Img fluid={data.gameOfThrones.childImageSharp.fluid} />}
            description="See our FAQ and the Terms of Service"
          />
          <Card
            as={Link}
            to="/breaking-bad/"
            header="Breaking Bad Intro Creator"
            image={<Img fluid={data.breakingBad.childImageSharp.fluid} />}
            description="See our FAQ and the Terms of Service"
          />
          <Card
            as={Link}
            to="/the-last-of-us/"
            header="The Last of Us Intro Creator"
            image={<Img fluid={data.theLastOfUs.childImageSharp.fluid} />}
            description="See our FAQ and the Terms of Service"
          />
          <Card
            as={Link}
            to="/house-of-the-dragon/"
            header="House of the Dragon Intro Creator"
            image={<Img fluid={data.houseOfTheDragon.childImageSharp.fluid} />}
            description="See our FAQ and the Terms of Service"
          />
        </Card.Group>
      )}
    />
    <br />
    <h1>Privacy Policy</h1>
    <Card
      fluid
      as={Link}
      to="/privacy/"
      header="Privacy Policy"
      description="Click here to read our Privacy Policy and understand how we collect, store, and process your information inserted in our services."
    />
    <h1>Contact</h1>
    <Card fluid>
      <Card.Content>
        Make sure your question is not covered by our FAQs and
        Terms of Service before contacting us.
        If you still need support contact us via email:
        {' '}
        <b><a href="mailto:support@kassellabs.io">support@kassellabs.io</a></b>
      </Card.Content>
    </Card>
  </Layout>
);

export default IndexPage;
